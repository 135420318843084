import * as React from "react";
const SvgMigrationIcon1 = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: 160, height: 39, viewBox: "0 0 160 39", fill: "none", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M10.405 3.95831L43.9492 7.57977L22.5629 36.1784L10.405 3.95831Z", fill: "#26468E" }), /* @__PURE__ */ React.createElement("mask", { id: "mask0_18407_6315", style: {
  maskType: "luminance"
}, maskUnits: "userSpaceOnUse", x: 10, y: 3, width: 34, height: 34 }, /* @__PURE__ */ React.createElement("path", { d: "M10.406 3.95831L43.9502 7.57977L22.5639 36.1784L10.406 3.95831Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask0_18407_6315)" }, /* @__PURE__ */ React.createElement("path", { d: "M14.0904 -0.422229C14.0904 -0.422229 19.0901 18.1724 36.1688 24.3466L24.7288 44.297C24.7288 44.297 4.01457 13.0314 4.0181 12.756C4.02163 12.4806 3.47437 -2.57251 3.47437 -2.57251L14.0904 -0.422229Z", fill: "#26468E" })), /* @__PURE__ */ React.createElement("path", { d: "M78.8779 8.29066L112.595 7.08429L95.5117 38.444L78.8779 8.29066Z", fill: "#18795C" }), /* @__PURE__ */ React.createElement("mask", { id: "mask1_18407_6315", style: {
  maskType: "luminance"
}, maskUnits: "userSpaceOnUse", x: 78, y: 7, width: 35, height: 32 }, /* @__PURE__ */ React.createElement("path", { d: "M78.8779 8.29066L112.595 7.08429L95.5117 38.444L78.8779 8.29066Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask1_18407_6315)" }, /* @__PURE__ */ React.createElement("path", { d: "M81.899 3.42872C81.899 3.42872 89.5021 21.1182 107.288 24.7915L98.8141 46.1707C98.8141 46.1707 73.8475 18.1829 73.8122 17.9099C73.7757 17.6368 71.0852 2.81671 71.0852 2.81671L81.899 3.42872Z", fill: "#18795C" })), /* @__PURE__ */ React.createElement("path", { d: "M44.634 7.61749L78.3571 8.6485L59.2341 38.8078L44.634 7.61749Z", fill: "#FF8E75" }), /* @__PURE__ */ React.createElement("mask", { id: "mask2_18407_6315", style: {
  maskType: "luminance"
}, maskUnits: "userSpaceOnUse", x: 44, y: 7, width: 35, height: 32 }, /* @__PURE__ */ React.createElement("path", { d: "M44.634 7.61749L78.3571 8.6485L59.2341 38.8078L44.634 7.61749Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask2_18407_6315)" }, /* @__PURE__ */ React.createElement("path", { d: "M47.9705 2.96627C47.9705 2.96627 54.3848 21.1207 71.8896 25.9638L62.0185 46.7345C62.0185 46.7345 38.961 17.1544 38.9433 16.8801C38.9257 16.6059 37.2214 1.63867 37.2214 1.63867L47.9705 2.96627Z", fill: "#FF8E75" })), /* @__PURE__ */ React.createElement("path", { d: "M112.356 7.80349L145.969 4.89172L130.496 37.0765L112.356 7.80349Z", fill: "#8FC58C" }), /* @__PURE__ */ React.createElement("mask", { id: "mask3_18407_6315", style: {
  maskType: "luminance"
}, maskUnits: "userSpaceOnUse", x: 112, y: 4, width: 34, height: 34 }, /* @__PURE__ */ React.createElement("path", { d: "M112.356 7.80349L145.969 4.89172L130.496 37.0765L112.356 7.80349Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask3_18407_6315)" }, /* @__PURE__ */ React.createElement("path", { d: "M115.127 2.79559C115.127 2.79559 123.616 20.0779 141.566 22.8449L134.185 44.6266C134.185 44.6266 107.833 17.9394 107.784 17.6687C107.734 17.398 104.295 2.73322 104.295 2.73322L115.127 2.79559Z", fill: "#8FC58C" })), /* @__PURE__ */ React.createElement("path", { d: "M78.4838 9.08275C32.3216 9.08275 1.97523 3.75236 1.52328 3.67115C0.516994 3.4899 -0.150341 2.52834 0.0309083 1.52205C0.212158 0.516937 1.17608 -0.150398 2.18002 0.0296752C2.85205 0.150901 70.5571 12.0251 157.897 0.0179077C158.901 -0.123326 159.843 0.586371 159.983 1.59855C160.122 2.61072 159.414 3.54404 158.401 3.68292C128.956 7.73162 101.714 9.08275 78.4838 9.08275Z", fill: "#0E352D" }), /* @__PURE__ */ React.createElement("path", { d: "M159.698 0.848854C159.415 1.28668 158.957 1.60798 158.399 1.68448C128.955 5.73318 101.714 7.08432 78.4824 7.08432C32.3202 7.08432 1.97377 1.75393 1.52182 1.67272C0.994546 1.57738 0.563783 1.26549 0.296616 0.846497C0.167152 1.0454 0.0741674 1.27255 0.0294435 1.52088C-0.151806 2.52717 0.515529 3.48874 1.52182 3.66999C1.97377 3.7512 32.3202 9.08159 78.4824 9.08159C101.714 9.08159 128.955 7.73046 158.399 3.68177C159.413 3.54289 160.12 2.60956 159.981 1.59739C159.944 1.32081 159.841 1.07012 159.698 0.848854Z", fill: "#0E352D" }));
export default SvgMigrationIcon1;
