import { useTranslation, Trans } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import MuiLink from '@mui/material/Link';
import merging from 'src/assets/images/merging.webp';
import NewsletterModal from '../newsletter-modal/newsletter-modal';
import useStyles from './styles';
import ChipInfo from '../shared/chip-info/chip-info';

const MergingNewsModal = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <NewsletterModal
      customClasses={{
        paper: classes.paper,
        closeWrapper: classes.closeWrapper,
        bottomSection: classes.bottomSection,
      }}
      text={
        <Box>
          <Box className={classes.mergingText}>
            <Box>
              <Typography className={classes.mergingTitle} variant="h2">
                {t('v2.global.modal_mergerAnnouncement_label_title')}
              </Typography>
            </Box>
            <Box className={classes.mergingDescription}>
              {t('v2.global.modal_mergerAnnouncement_label_body')}
            </Box>

            <ChipInfo
              type="info"
              hasIcon={false}
              text={
                <Trans
                  i18nKey="v2.global.modal_mergerAnnouncement_label_banner"
                  components={[
                    <MuiLink
                      href={`mailto:${import.meta.env.VITE_CONTACT_EMAIL}`}
                      style={{
                        color: 'inherit',
                        fontWeight: 'bold',
                        fontSize: 'inherit',
                      }}
                      rel="noopener noreferrer"
                    />,
                  ]}
                />
              }
            />
          </Box>
        </Box>
      }
      storageKey="MERGING_NEWSLETTER"
      banner={merging}
    />
  );
};

export default MergingNewsModal;
