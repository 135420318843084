import CloseButton from 'src/components/shared/side-drawer/close-button';
import { Box } from '@mui/material';
import clsx from 'clsx';
import useStyles from './styles';
import ModalWithConfirmation, {
  ModalWithConfirmationProps,
} from './modal-with-confirmation/modal-with-confirmation';

type ModalProps = ModalWithConfirmationProps & { header?: React.ReactNode };

const Modal = (props: ModalProps) => {
  const { onClose, header, customClasses } = props;
  const { classes } = useStyles();

  return (
    <ModalWithConfirmation
      {...props}
      header={
        <>
          <Box
            className={clsx(classes.closeWrapper, customClasses?.closeWrapper)}
          >
            <CloseButton onClose={onClose} />
          </Box>

          {header}
        </>
      }
      customClasses={{ ...classes, ...customClasses }}
    />
  );
};

export default Modal;
