import { Navigate, Outlet } from 'react-router-dom';
import AuthorizedLayout from 'src/layout/authorized-layout';
import MergingNewsModal from 'src/components/merging-news-modal/merging-news-modal';
import BookingWidget from 'src/components/booking-widget/booking-widget';
import { LikeMindedApp } from '../likeminded-app';
import AppDrawer from '../app-drawer';
import AuthProvider from '../auth-provider';

interface ProtectedRouteProps {
  isAllowed: boolean;
  redirectPath: string;
}

export const ProtectedRoute = ({
  isAllowed,
  redirectPath,
}: ProtectedRouteProps) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return (
    <AuthProvider>
      <AuthorizedLayout>
        <LikeMindedApp />
        <BookingWidget />
        <MergingNewsModal />
        <AppDrawer />
        <Outlet />
      </AuthorizedLayout>
    </AuthProvider>
  );
};

export const ProtectedRouteWithoutLayout = ({
  isAllowed,
  redirectPath,
}: ProtectedRouteProps) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return (
    <AuthProvider>
      <LikeMindedApp />
      <BookingWidget />
      <AppDrawer />
      <Outlet />
    </AuthProvider>
  );
};
