import clsx from 'clsx';
import { Box, Typography } from '@mui/material';
import SvgIcons from './assets';
import { useStyles } from './styles';

interface ChipInfoProps {
  text: string;
  type: 'info' | 'warning';
  className?: string;
  hasIcon?: boolean;
}

const ChipInfo = ({ text, type, className, hasIcon = true }: ChipInfoProps) => {
  const { classes } = useStyles();

  return (
    <Box
      className={clsx(
        classes.infoBanner,
        type === 'info' && classes.infoStyle,
        type === 'warning' && classes.warningStyle,
        className,
      )}
    >
      <Box>
        {hasIcon && (
          <>
            {type === 'warning' ? (
              <SvgIcons.WarningCircleIcon className={classes.infoIcon} />
            ) : (
              <SvgIcons.InfoCircleIcon className={classes.infoIcon} />
            )}
          </>
        )}
      </Box>
      <Box className={classes.textStyle}>
        <Typography variant="caption">{text}</Typography>
      </Box>
    </Box>
  );
};

export default ChipInfo;
