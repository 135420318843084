// import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Box, Typography, Button } from '@mui/material';
import MigrationIcon1 from 'src/assets/icons/migration-icon1.svg?react';
import MigrationIcon2 from 'src/assets/icons/migration-icon2.svg?react';
import MigrationBody1 from 'src/assets/icons/migration-body1.svg?react';
import MigrationBody2 from 'src/assets/icons/migration-body2.svg?react';
import MigrationBody3 from 'src/assets/icons/migration-body3.svg?react';
import MirgrationSecondBtn from 'src/assets/icons/migration-second-btn.svg?react';
import { useGetUser } from 'src/apis/users-api';
import LogoLargeSVG from 'src/assets/icons/likeminded-logo-large.svg?react';
import LogoSVG from 'src/assets/icons/likeminded-logo.svg?react';
import LanguageSelect from 'src/layout/language-select';
import ShieldSVG from 'src/assets/icons/shield.svg?react';
import MuiLink from '@mui/material/Link';

import { useStyles } from './style';

const MigrationPage = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { data: user } = useGetUser();
  const niloAppUrl = `${
    import.meta.env.VITE_NILO_APP_URL
  }?userEmail=${encodeURIComponent(user?.email)}`;
  return (
    <Box>
      <Box component="span" className={classes.TopBar}>
        <LogoLargeSVG className={classes.logo} />
        <LogoSVG className={classes.logoMobile} />
        <LanguageSelect />
      </Box>
      <Box className={classes.mainContainer}>
        <Box className={classes.contentContainer}>
          <Box className={classes.contentStlye}>
            <Box className={classes.headerLogo}>
              <MigrationIcon1 />
              <MigrationIcon2 />
            </Box>
            <Box className={classes.contentHeader}>
              <Typography variant="h2" className={classes.title}>
                {t('v2.migrationPage_label_title')}
              </Typography>
              <Typography variant="body1">
                {t('v2.migrationPage_label_description')}
              </Typography>
            </Box>
            <Box className={classes.contentBody}>
              <Box className={classes.contentBox}>
                <MigrationBody1 width={32} height={32} />
                <Box className={classes.contentBoxText}>
                  <Typography variant="h4Bold">
                    {t('v2.migrationPage_label_sessions_title')}
                  </Typography>
                  <Typography variant="body1">
                    {t('v2.migrationPage_label_sessions_body')}
                  </Typography>
                </Box>
              </Box>
              <Box className={classes.contentBox}>
                <MigrationBody2 />
                <Box className={classes.contentBoxText}>
                  <Typography variant="h4Bold">
                    {t('v2.migrationPage_label_matching_title')}
                  </Typography>
                  <Typography variant="body1">
                    {t('v2.migrationPage_label_matching_body')}
                  </Typography>
                </Box>
              </Box>
              <Box className={classes.contentBox}>
                <MigrationBody3 />
                <Box className={classes.contentBoxText}>
                  <Typography variant="h4Bold">
                    {t('v2.migrationPage_label_getStarted_title')}
                  </Typography>
                  <Typography variant="body1">
                    {t('v2.migrationPage_label_getStarted_body')}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box className={classes.migrationFooterDiv}>
              <Box className={classes.migrationFooter}>
                <ShieldSVG className={classes.migrationFooterIcon} />
                <Typography>
                  <Trans
                    className={classes.migrationFooterText}
                    i18nKey="v2.migrationPage_label_privacyPolicy"
                    components={[
                      <MuiLink
                        href={`mailto:${import.meta.env.VITE_CONTACT_EMAIL}`}
                        style={{ color: '#343535', fontWeight: 'bold' }}
                        rel="noopener noreferrer"
                      />,
                    ]}
                  />
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className={classes.migrationButtonBox}>
            <Button
              variant="contained"
              onClick={() => {
                window.open(niloAppUrl, '_blank');
              }}
            >
              {t('v2.global.button_label_letsgo')}
            </Button>
            <Button
              variant="outlined"
              className={classes.learnMore}
              onClick={() => {
                window.open(
                  'https://nilohealth.elevio.help/categories/21-former-likeminded-users',
                  '_blank',
                );
              }}
            >
              <Box className={classes.learnMoreBox}>
                <Typography className={classes.learnMoreText}>
                  {t('v2.global.button_label_learnMore')}
                </Typography>
                <MirgrationSecondBtn />
              </Box>
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MigrationPage;
