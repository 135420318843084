import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  paper: {
    height: 'auto',
    margin: '0 16px',
    padding: 0,
    width: 418,
    overflow: 'auto',
    display: 'block',
  },
  topSection: {
    position: 'static',
  },
  bottomSection: {
    padding: '0 24px 24px 24px',
  },
  description: {
    padding: 0,
  },
  closeWrapper: {
    height: 69,
    backgroundColor: theme.colors.white,
    display: 'flex',
    alignItems: 'center',
    position: 'sticky',
    top: 0,
    borderRadius: '16px 16px 0 0',
  },
  dialogContent: {
    display: 'grid',
    gap: 16,
    marginBottom: 0,
  },
  dialogClose: {
    position: 'absolute',
    top: 12,
    right: 10,
  },
  dialogButton: {
    width: 'fit-content',
  },
}));

export default useStyles;
