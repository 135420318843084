import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  paper: {
    width: 508,
    height: 'auto',
    margin: '0 16px',
    padding: 0,
    display: 'block',
  },
  closeWrapper: {
    position: 'static',
  },

  mergingTitle: {
    color: theme.colors.darkestGray,
    [theme.breakpoints.down(320)]: {
      padding: 0,
    },
  },
  bottomSection: {
    paddingBottom: 16,
    width: '100%',
  },

  mergingDescription: {
    margin: '16px 0 24px 0',
  },
  mergingText: {
    [theme.breakpoints.down('sm')]: {
      maxHeight: '280px',
      maxWidth: '100%',
      height: 'auto',
      width: 'auto',
      overflow: 'auto',
    },
  },
  mergingButton: {
    marginTop: 24,
  },
}));

export default useStyles;
