import { useEffect } from 'react';
import useLocalStorage from 'src/hooks/use-local-storage';
import { useGetUser } from 'src/apis/users-api';
import useStyles from './styles';
import Modal from '../shared/modal/modal';

interface NewsletterModalProps {
  storageKey: string;
  title: string;
  text: string;
  banner: string;
  customClasses: Record<string, string>;
}

const NewsletterModal = ({
  storageKey,
  title,
  text,
  banner,
  customClasses,
}: NewsletterModalProps) => {
  const { classes } = useStyles();

  const { data: user, isFetched } = useGetUser();
  const [modal, setModal] = useLocalStorage(storageKey, {
    hidden: false,
  });

  const isUnlimited = user?.subscription?.type === 'UNLIMITED';

  useEffect(() => {
    if (isFetched && !modal.hidden) {
      setModal({ hidden: isUnlimited });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUnlimited]);

  const handleClose = (): void => {
    setModal({ hidden: true });
  };

  return (
    <Modal
      onClose={handleClose}
      isOpen={!modal?.hidden}
      title={title}
      text={text}
      header={
        <img src={banner} alt="likeminded-banner" className={classes.image} />
      }
      customClasses={customClasses}
    />
  );
};

export default NewsletterModal;
