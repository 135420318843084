import { makeStyles } from 'tss-react/mui';
import { ITheme } from 'src/style/theme';

export const useStyles = makeStyles()((theme: ITheme) => ({
  mainContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
  },
  contentContainer: {
    width: '560px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      marginTop: '24px',
      paddingBottom: '180px',
    },
  },

  TopBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '16px',
    padding: '67px 150px 37px 150px',
    alignSelf: 'stretch',
    flexShrink: 0,
    [theme.breakpoints.down('sm')]: {
      padding: '20px 24px',
      height: '64px',
      boxShadow: '0px 6px 12px 0px rgba(27, 27, 28, 0.15)',
    },
  },

  logo: {
    height: '18.411px',
    width: '122.63px;',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  logoMobile: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  contentStlye: {
    gap: '24px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 24px',
    },
  },

  headerLogo: {
    display: 'flex',
    padding: '8px 0px',
    marginBottom: '16px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 15,
  },
  title: {
    marginBottom: 8,
  },
  contentHeader: {
    marginBottom: 24,
  },
  contentBody: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
  },
  contentBox: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
    gap: 16,
    alignSelf: 'stretch',
    flexShrink: 0,
    marginBottom: 24,
  },
  contentBoxText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 8,
    flex: '1px 0px 0px',
    width: '105%',
  },
  migrationFooterDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '16px',
    [theme.breakpoints.down('sm')]: {
      order: 0,
      margin: 0,
    },
  },

  migrationFooter: {
    display: 'inline-flex',
    backgroundColor: '#F4F1FC',
    borderRadius: '8px',
    padding: '8px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
    },
  },
  migrationFooterIcon: {
    width: '42px',
    height: '42px',
    [theme.breakpoints.down('sm')]: {
      width: '25%',
    },
  },
  migrationFooterText: {
    color: theme.colors.darkestGray,
    fontWeight: 700,
  },
  migrationButtonBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '16px',
    alignSelf: 'stretch',
    marginTop: 8,
    paddingBottom: 12,
    [theme.breakpoints.down('sm')]: {
      borderTop: '1px solid #DCE0E0',
      padding: '12px 24px',
      position: 'fixed',
      bottom: 0,
      width: '100%',
      background: '#ffff',
    },
  },
  learnMore: {
    border: 'none',
    '&:hover': {
      border: 'none',
      color: theme.colors.mediumDarkGreen,
    },
  },
  learnMoreBox: {
    display: 'flex',
    padding: '12px 24px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8,
    borderRadius: '8px',
  },
  learnMoreText: {
    fontWeight: 700,
  },
}));
